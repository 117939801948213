const pages = [
  {
    name: "events",
    href: "/events/explore",
    icon: "calendar_month",
    title: "Eventos",
    hidden: true,
    routes: {
      overview: ["Novedades", "newspaper", "/overview", false],
      eventExplore: ["Explorar", "explore", "/events/explore", true],
      events: ["Mis eventos", "event_note", "/events", true],
      eventSuggest: ["Sugeridos", "assistant", "/events/suggest", false],
      event: ["Evento", "event_available", "/event/:eid", 0],
      eventCalendar: ["Calendario", "calendar_month", "/events/calendar", 0],
      eventPaymentConfirmation: [
        "Confirmación pago",
        "receipt_long",
        "/events/paymentConfirmation",
        0,
      ],
      eventHistory: ["Historial", "history", "/events/history", 0],
    },
  },
  {
    name: "organizer",
    href: "/organizer/eventsOverview/",
    icon: "calendar_month",
    title: "Organizador",
    hidden: true,
    routes: {
      activateAccount: [
        "Activar Cuenta",
        "newspaper",
        "/organizer/activateAccount",
        false,
      ],
      eventsOverview: [
        "Tus Eventos",
        "assistant",
        "/organizer/eventsOverview",
        true,
      ],
    },
  },
  {
    name: "community",
    href: "/groups",
    icon: "people",
    title: "Comunidad",
    hidden: true,
    routes: {
      groups: ["Mis grupos", "workspaces", "/groups", true],
      friends: ["Mis amigos", "people_alt", "/friends", true],
      feed: ["Publicaciones", "feed", "/feed", false],
      friend: ["Contacto", "perm_contact_calendar", "/user/:uid", 0],
      friendBirthdays: ["Cumpleaños", "cake", "/friends/birthdays", 0],
      friendList: ["Contactos", "book", "/friends/network", 0],
      friendRequests: ["Solicitudes", "mail_outline", "/friends/requests", 0],
      friendSuggests: ["Sugerencias", "assistant", "/friends/suggests", 0],
      group: ["Grupo", "group_work", "/group/:gid", 0],
    },
  },
  {
    name: "benefits",
    href: "/benefits",
    icon: "auto_awesome",
    title: "Beneficios",
    hidden: false,
    routes: {
      benefits: ["Mis beneficios", "health_and_safety", "/benefits", true],
      subscription: ["Suscripción", "credit_card", "/subscription", true],
      offers: ["Ofertas", "local_offer", "/offers", true],
    },
  },
  {
    name: "account",
    href: "/profile",
    hidden: true,
    icon: "account_circle",
    title: "Cuenta",
    routes: {
      profile: ["Mi perfil", "account_circle", "/profile", true],
      interests: ["Intereses", "interests", "/profile/interests", true],
      messages: ["Mensajes", "chat", "/messages", 0],
      notifications: ["Notificaciones", "notifications", "/notifications", 0],
      settings: ["Ajustes", "settings", "/settings", 0],
      help: ["Ayuda", "help", "/help", 0],
    },
  },
];

export default pages;
