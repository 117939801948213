import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Icon, Link } from "components";
import { paths } from "routes";
import PropTypes from "prop-types";

function BupBreadcrumbs({ breadcrumbs }) {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className="BupBreadcrumbs-root"
      separator={<Icon name="chevron_right" />}
    >
      <Link to={paths.eventExplore}>
        <Icon name="home" />
      </Link>
      {breadcrumbs.map(([name, url], i) => (
        <Link key={i} to={url}>
          {name}
        </Link>
      ))}
    </Breadcrumbs>
  );
}

BupBreadcrumbs.propTypes = {
  children: PropTypes.node,
};

export default BupBreadcrumbs;
